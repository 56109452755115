.container {
  width: 100%;
}

.mainVideo {
  width: 75vw;
  height: 42.1875vw;
}

header {
  width: 100%;
  height: 50px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  font-weight: bold;
  font-style: italic;
}

header > p {
  cursor: pointer;
}

.options {
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.optionSelection {
  width: 24.64vw;
  height: 13.8vw;
}

.main {
  width: 100%;
  display: flex;
}

.clickable-parent {
  position: relative;
}

.clickable {
  width: 24.64vw;
  height: 13.8vw;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .main {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .mainVideo {
    width: 100vw;
    height: 56.25vw;
  }

  .options {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .optionSelection {
    width: 50vw;
    height: 28.125vw;
  }

  .clickable-parent {
    position: relative;
  }

  .clickable {
    width: 50vw;
    height: 28.125vw;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}
